/* eslint-disable no-return-await */
import {
  getAuth, signInWithEmailAndPassword, signInWithPopup, OAuthProvider, GoogleAuthProvider, FacebookAuthProvider, sendPasswordResetEmail, signInWithRedirect,
} from 'firebase/auth';
import {
  getDatabase, ref, onValue, set, get, child,
} from 'firebase/database';
import 'firebase/analytics';
import mixpanel, { get_distinct_id } from 'mixpanel-browser';
import instance from './axios';
import { currentDistinctId } from '../hooks/useAnalytics';
import { versionByEmail } from '../utils/Versioning';
import apiService from '../services/apiService';

const sendNewsletter = async (email) => {
  try {
    const result = await instance.post('/api/newsletter/', {
      email,
    });
    console.log(result);
  } catch (error) {
    console.log(error);
  }
};

export const loginHandler = async (email, password, type, cb) => {
  const db = getDatabase();
  const dbRef = ref(db, '/');
  const platform = navigator.userAgent.toLowerCase();
  const isIos = /ipad|iphone|ipod|ios/.test(platform);
  try {
    switch (type) {
      case 'email/username':
        if (!email.includes('@')) {
          return await new Promise(async (resolve) => {
            const snap = await get(child(dbRef, `/UsernameData/${email}`));
            const userID = snap.val();
            if (userID && userID !== null) {
              const result = await get(child(dbRef, `/UserProfile/${userID}/Email`));
              email = result.val();
              const userDetail = await loginWithEmail(email, password);
              console.log(userDetail, email, password, 'userDetail auth');
              resolve(userDetail);
            } else {
              resolve('Username not found');
            }
          });
        }
        const userDetail = await loginWithEmail(email, password);
        console.log(userDetail, 'userDetail auth');
        return userDetail;

        break;

      case 'apple':
        if (navigator.userAgent.toLowerCase().includes('assemblr') && isIos) {
          window.location = 'assemblr://applelogin';
        } else {
          cb();
          return await loginWithApple();
        }
        break;

      case 'google':
        cb();
        return await loginWithGoogle();
        break;

      case 'facebook':
        if (navigator.userAgent.toLowerCase().includes('assemblr')) {
          window.location = 'assemblr://fblogin';
        } else {
          cb();
          return await loginWithFacebook();
        }
        break;

      default:
        break;
    }
  } catch (error) {
  }
};

export const loginWithFacebook = async () => {
  if (navigator.userAgent.includes('Assemblr')) {
    window.location = 'assemblr://fblogin';
  } else {
    const provider = new FacebookAuthProvider();
    const auth = getAuth();
    const db = getDatabase();
    const dbRef = ref(db, '/');
    return await new Promise(async (resolve) => {
      signInWithPopup(auth, provider).then(async (result) => {
        const { uid, email } = result.user;
        // const snap = await get(child(dbRef, `/UserProfile/${uid}`));
        const _userData = await new Promise(async (resolve) => {
          try {
          const { data } = await apiService.get(`/user-profile/${res.user.uid}`);
          resolve(data);
          } catch (error) {
            resolve(null);
          }
        });
        if (_userData && _userData !== null) {
          const userData = {
            FirebaseID: uid,
            Email: email,
            accessToken: result.user.accessToken,
            ..._userData,
          };
          window.dataLayer.push({
            event: 'login',
            data: {
              method: 'facebook',
              uuid: userData.FirebaseID,
            },
          });
          window.dataLayer.push({
            event: 'auth_validated',
            data: {
              uuid: userData.FirebaseID,
              user_avatar: userData.ProfilePictureURL,
              user_name: userData.Username,
              user_display_name: userData.Name,
            },
          });
          localStorage.setItem('already_validated', 'true');
          resolve(userData);
        } else {
          const isMobile = {
            Android() {
              return navigator.userAgent.match(/Android/i);
            },
            iOS() {
              return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
          };
          let Source = 3;
          if (isMobile.Android()) {
            // context.setState({viewConfirmation:true, viewMode:mode})
            Source = 4;
          }
          if (isMobile.iOS()) {
            // context.setState({viewConfirmation:true, viewMode:mode})
            Source = 4;
          }
          if (navigator.userAgent.toLowerCase().includes('assemblr')) {
            Source = 4;
          }
          const { user } = result;
          const userDisplayName = (`user${user.uid.substr(user.uid.length - 7)}`).toLowerCase();
          const userData = {
            Name: userDisplayName,
            Username: userDisplayName,
            FirebaseID: uid,
            Email: email,
            accessToken: result.user.accessToken,
            Country: '',
            Bio: '',
            Birthdate: '',
            Coin: 0,
            Diamond: 0,
            Source,
            AgreementPrivacyPolicy: true,
            ProfilePictureURL: 'https://asset.asblr.app/Asset/placeholder/defaultProfilePicture.jpeg',
            version: versionByEmail(email, result?.user?.metadata?.createdAt),
          };
          // insert User to Database
          set(ref(db, `/UserProfile/${user.uid}`), userData);
          // insert UserID to Database
          set(ref(db, `/UsernameData/${userDisplayName}`), user.uid);
          window.dataLayer.push({
            event: 'sign_up',
            data: {
              method: 'facebook',
              uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
            },
          });
          mixpanel.track('sign_up', {
            method: 'facebook',
            uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
          });
          window.dataLayer.push({
            event: 'auth_validated',
            data: {
              uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
              user_avatar: userData.ProfilePictureURL,
              user_name: userData.Username,
              user_display_name: userData.Name,
            },
          });
          if (localStorage.getItem('newsletter') === 'true') {
            sendNewsletter(email);
          }
          localStorage.setItem('already_validated', 'true');
          resolve(userData);
        }
      }).catch((err) => {
        console.log({ err }, 'error login facebook');
        if (err.code === 'auth/popup-closed-by-user' || err.code === 'auth/cancelled-popup-request') resolve('auth/popup-closed-by-user');
        else resolve('error');
      });
    });
  }
};

export const loginWithGoogle = async () => {
  if (navigator.userAgent.includes('Assemblr')) {
    window.location = 'assemblr://googlelogin';
  } else {
    const provider = new GoogleAuthProvider();
    const auth = getAuth();
    const db = getDatabase();
    const dbRef = ref(db, '/');
    return await new Promise(async (resolve) => {
      signInWithPopup(auth, provider).then(async (result) => {
        const { uid, email } = result.user;
        // const snap = await get(child(dbRef, `/UserProfile/${uid}`));
        const _userData = await new Promise(async (resolve) => {
          try {
          const { data } = await apiService.get(`/user-profile/${res.user.uid}`);
          resolve(data);
          } catch (error) {
            resolve(null);
          }
        });
        if (_userData && _userData !== null) {
          const userData = {
            FirebaseID: uid,
            Email: email,
            accessToken: result.user.accessToken,
            ..._userData,
          };
          window.dataLayer.push({
            event: 'login',
            data: {
              method: 'google',
              uuid: userData.FirebaseID,
            },
          });
          window.dataLayer.push({
            event: 'auth_validated',
            data: {
              uuid: userData.FirebaseID,
              user_avatar: userData.ProfilePictureURL,
              user_name: userData.Username,
              user_display_name: userData.Name,
            },
          });
          localStorage.setItem('already_validated', 'true');
          if (localStorage.getItem('newsletter') === 'true') {
            sendNewsletter(email);
          }
          resolve(userData);
        } else {
          const { user } = result;
          const isMobile = {
            Android() {
              return navigator.userAgent.match(/Android/i);
            },
            iOS() {
              return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
          };
          let Source = 3;
          if (isMobile.Android()) {
            // context.setState({viewConfirmation:true, viewMode:mode})
            Source = 4;
          }
          if (isMobile.iOS()) {
            // context.setState({viewConfirmation:true, viewMode:mode})
            Source = 4;
          }
          if (navigator.userAgent.toLowerCase().includes('assemblr')) {
            Source = 4;
          }

          const userDisplayName = (`user${user.uid.substr(user.uid.length - 7)}`).toLowerCase();
          const userData = {
            Name: userDisplayName,
            Username: userDisplayName,
            FirebaseID: uid,
            Email: email,
            accessToken: result.user.accessToken,
            Country: '',
            Bio: '',
            Birthdate: '',
            Coin: 0,
            Diamond: 0,
            Source,
            AgreementPrivacyPolicy: true,
            ProfilePictureURL: 'https://asset.asblr.app/Asset/placeholder/defaultProfilePicture.jpeg',
            version: versionByEmail(email, result?.user?.metadata?.createdAt),
          };
          // insert User to Database
          set(ref(db, `/UserProfile/${user.uid}`), userData);
          // insert UserID to Database
          set(ref(db, `/UsernameData/${userDisplayName}`), user.uid);
          window.dataLayer.push({
            event: 'sign_up',
            data: {
              method: 'google',
              uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
            },
          });

          mixpanel.track('sign_up', {
            method: 'google',
            uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
          });

          window.dataLayer.push({
            event: 'auth_validated',
            data: {
              uuid: userData.FirebaseID,
              user_avatar: userData.ProfilePictureURL,
              user_name: userData.Username,
              user_display_name: userData.Name,
            },
          });
          if (localStorage.getItem('newsletter') === 'true') {
            sendNewsletter(email);
          }
          localStorage.setItem('already_validated', 'true');
          resolve(userData);
        }
      }).catch((err) => {
        console.log({ err }, 'error login google');
        if (err.code === 'auth/popup-closed-by-user' || err.code === 'auth/cancelled-popup-request') resolve('auth/popup-closed-by-user');
        else resolve('error');
      });
    });
  }
};

export const loginWithApple = async () => {
  const provider = new OAuthProvider('apple.com');
  const auth = getAuth();
  const db = getDatabase();
  const dbRef = ref(db, '/');
  return await new Promise(async (resolve) => {
    if (navigator.userAgent.includes('Assemblr')) {
      signInWithRedirect(auth, provider);
    } else {
      signInWithPopup(auth, provider).then(async (result) => {
        const { uid, email } = result.user;
        // const snap = await get(child(dbRef, `/UserProfile/${uid}`));
        const _userData = await new Promise(async (resolve) => {
          try {
          const { data } = await apiService.get(`/user-profile/${res.user.uid}`);
          resolve(data);
          } catch (error) {
            resolve(null);
          }
        });
        if (_userData && _userData !== null) {
          const userData = {
            FirebaseID: uid,
            Email: email,
            accessToken: result.user.accessToken,
            ..._userData,
          };
          window.dataLayer.push({
            event: 'login',
            data: {
              method: 'apple',
              uuid: userData.FirebaseID,
            },
          });
          window.dataLayer.push({
            event: 'auth_validated',
            data: {
              uuid: userData.FirebaseID,
              user_avatar: userData.ProfilePictureURL,
              user_name: userData.Username,
              user_display_name: userData.Name,
            },
          });
          localStorage.setItem('already_validated', 'true');
          resolve(userData);
        } else {
          const { user } = result;
          const isMobile = {
            Android() {
              return navigator.userAgent.match(/Android/i);
            },
            iOS() {
              return navigator.userAgent.match(/iPhone|iPad|iPod/i);
            },
          };
          let Source = 3;
          if (isMobile.Android()) {
            // context.setState({viewConfirmation:true, viewMode:mode})
            Source = 4;
          }
          if (isMobile.iOS()) {
            // context.setState({viewConfirmation:true, viewMode:mode})
            Source = 4;
          }
          if (navigator.userAgent.toLowerCase().includes('assemblr')) {
            Source = 4;
          }
          const userDisplayName = (`user${user.uid.substr(user.uid.length - 7)}`).toLowerCase();
          const userData = {
            Name: userDisplayName,
            Username: userDisplayName,
            FirebaseID: uid,
            Email: email,
            accessToken: result.user.accessToken,
            Country: '',
            Bio: '',
            Birthdate: '',
            Coin: 0,
            Diamond: 0,
            Source,
            AgreementPrivacyPolicy: true,
            ProfilePictureURL: 'https://asset.asblr.app/Asset/placeholder/defaultProfilePicture.jpeg',
            version: versionByEmail(email, result?.user?.metadata?.createdAt),
          };
          // insert User to Database
          set(ref(db, `/UserProfile/${user.uid}`), userData);
          // insert UserID to Database
          set(ref(db, `/UsernameData/${userDisplayName}`), user.uid);
          window.dataLayer.push({
            event: 'sign_up',
            data: {
              method: 'apple',
              uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
            },
          });

          mixpanel.track('sign_up', {
            method: 'apple',
            uuid: !currentDistinctId()?.includes?.('$device') ? currentDistinctId() : userData.FirebaseID,
          });

          window.dataLayer.push({
            event: 'auth_validated',
            data: {
              uuid: userData.FirebaseID,
              user_avatar: userData.ProfilePictureURL,
              user_name: userData.Username,
              user_display_name: userData.Name,
            },
          });
          if (localStorage.getItem('newsletter') === 'true') {
            sendNewsletter(email);
          }
          localStorage.setItem('already_validated', 'true');
          resolve(userData);
        }
      }).catch((err) => {
        console.log({ err }, 'error login apple');
        if (err.code === 'auth/popup-closed-by-user' || err.code === 'auth/cancelled-popup-request') resolve('auth/popup-closed-by-user');
        else resolve('error');
      });
    }
  });
};

export const loginWithEmail = async (email, password) => {
  const auth = getAuth();
  const db = getDatabase();
  const dbRef = ref(db, '/');
  return await new Promise(async (resolve) => {
    signInWithEmailAndPassword(auth, email, password).then(async (res) => {
      const userID = res.user.uid;
      // const snap = await get(child(dbRef, `/UserProfile/${res.user.uid}`));
      const _userData = await new Promise(async (resolve) => {
        try {
        const { data } = await apiService.get(`/user-profile/${res.user.uid}`);
        resolve(data);
        } catch (error) {
          resolve(null);
        }
      });
      if (_userData && _userData !== null) {
        const userData = {
          FirebaseID: userID,
          Email: email,
          ..._userData,
          accessToken: auth.currentUser.accessToken,
        };
        window.dataLayer.push({
          event: 'login',
          data: {
            method: 'email',
            uuid: userData.FirebaseID,
          },
        });
        window.dataLayer.push({
          event: 'auth_validated',
          data: {
            uuid: userData.FirebaseID,
            user_avatar: userData.ProfilePictureURL,
            user_name: userData.Username,
            user_display_name: userData.Name,
          },
        });
        localStorage.setItem('already_validated', 'true');
        resolve(userData);
      } else {
        resolve('not register');
      }
    }).catch((err) => {
      let err_msg = '';
      switch (err.code) {
        case 'auth/user-not-found':
          err_msg = 'User not found';
          break;

        case 'auth/wrong-password':
          err_msg = 'Wrong password';
          break;

        default:
          err_msg = err.code;
          break;
      }
      resolve(err_msg);
    });
  });
};

export const resetPassword = async (email) => {
  const auth = getAuth();
  const db = getDatabase();
  return await new Promise(async (resolve) => {
    sendPasswordResetEmail(auth, email).then((resolve) => {
      alert(`Reset link has been send to ${email}`);
    }).catch((err) => {
      alert('Email not register');
    });
  });
};
