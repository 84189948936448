/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable no-return-await */
/* eslint-disable no-plusplus */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-shadow */
/* eslint-disable no-useless-escape */
/* eslint-disable no-case-declarations */
import { FaFacebook } from '@react-icons/all-files/fa/FaFacebook';
import { FaApple } from '@react-icons/all-files/fa/FaApple';
import { FcGoogle } from '@react-icons/all-files/fc/FcGoogle';
import { FiMail } from '@react-icons/all-files/fi/FiMail';
import { RiEyeCloseLine } from '@react-icons/all-files/ri/RiEyeCloseLine';
import { RiEyeLine } from '@react-icons/all-files/ri/RiEyeLine';
import { GrClose } from '@react-icons/all-files/gr/GrClose';
import { useState, useEffect, useMemo } from 'react';
import { getCountries } from '@loophq/country-state-list';
import { getAuth, getIdToken } from 'firebase/auth';
import {
  getDatabase, ref, set, get, child,
} from 'firebase/database';
import 'firebase/analytics';
import { useDispatch, useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { jwtDecode } from 'jwt-decode';
import styles from '../../styles/Navbar.module.scss';
import Loader from '../miscellaneous/loader';
import { setLogin } from '../../store/actions/popupActions';
import { loginHandler, resetPassword } from '../../config/auth';
import instance from '../../config/axios';
import { loginActivitiesAnalyticTracking } from '../controllers/analytic_tracking';
import { appState, setIframePopup } from '../../store/reducers/appReducer';
import useAuth from '../../hooks/useAuth';
import classnames from '../../utils/classnames';
import AuthenticationServices from '../../services/AuthenticationServices';

const termLink = {
  web: 'https://www.assemblrworld.com/pages/term-and-condition',
  mobile: 'assemblr://openurl?url=https://www.assemblrworld.com/pages/term-and-condition',
};

const policyLink = {
  web: 'https://www.assemblrworld.com/pages/privacy-policy',
  mobile: 'assemblr://openurl?url=https://pwa.assemblrworld.com/PrivacyPolicy',
};

export default function Login({ onSuccessLogin, onClose }) {
  const showLogin = useSelector((state) => state.popup.login);
  const [showForm, setShowForm] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [, setCountryList] = useState([]);
  const [user, setUser] = useState({
    email: '',
    password: '',
  });
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({
    name: '',
    username: '',
    email: '',
    country: '',
    password: '',
    confpassword: '',
    subcribe: false,
    tnc: false,
  });
  const [errors, setErrors] = useState({
    name: '',
    username: '',
    email: '',
    country: '',
    password: '',
    confpassword: '',
  });
  const [errorLogin, setErrorLogin] = useState({
    email: '',
    password: '',
  });
  const [errorType, setErrorType] = useState('');
  const [searchCountry, setSearchCountry] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loginPopup, setLoginPopup] = useState('');
  const [tnc, setTNC] = useState(false);
  const [newsletter, setNewsletter] = useState(false);
  const timestamp = Date.now();
  const {
    checkAvailable, setUserData: setUserDataDispatch, userData: currentUser, user: _currentUser,
  } = useAuth();
  const {
    push, query,
  } = useRouter();

  const handleEditorEmbedLogin = async () => {
    setLoading(true);
    setTimeout(async () => {
      const token = await AuthenticationServices().generateToken();
      const _token = localStorage.getItem('_token');
      const uid = currentUser?.FirebaseID || _currentUser?.uid || jwtDecode(_token)?.uid;
      onSuccessLogin?.({ token, uid });
    }, 1000);
  };

  const registerReward = async (token) => {
    try {
      const result = await instance.post('/api/v2/transactions/reward/', {
        action: 'register',
      }, {
        headers: { Authorization: token },
      });
      console.log(token, result, 'register new user');
    } catch (error) {
      console.log(error);
    }
  };

  const sendNewsletter = async (email) => {
    try {
      const result = await instance.post('/api/newsletter/', {
        email,
      });
      console.log(result);
    } catch (error) {
      console.log(error);
    }
  };

  const userName = async (userDt) => {
    const db = getDatabase();
    const dbRef = ref(db, '/');
    const { username } = userDt;
    get(child(dbRef, `/UsernameData/${username}`))
      .then(async (snap) => {
        const result = snap.val();
        if (result === null) {
          const test = await checkAvailable(userDt);
          switch (test) {
            case 'Email Already In Use':
              setErrors({
                name: errors.name,
                username: '',
                email: 'Email Already In Use',
                password: errors.password,
                confpassword: errors.confpassword,
              });
              setLoading(false);
              break;

            default:
              setErrors({
                name: errors.name,
                username: errors.username,
                email: '',
                password: errors.password,
                confpassword: errors.confpassword,
              });
              setLoading(false);
              const auth = getAuth();
              const token = await getIdToken(auth.currentUser);
              console.log(auth, token, 'register new user');
              if (!query?.content) { //kalau registernya bukan dari link redeem/voucher (register biasa)
                if (onSuccessLogin) {
                  handleEditorEmbedLogin();
                } else {
                  push({ pathname: '/projects', query: { ...query } });
                }
              }
              registerReward(token, auth.currentUser.uid);
              localStorage.setItem('show-reward-popup', false);
              break;
          }
        } else {
          setErrors({
            name: errors.name,
            username: 'Username already in use',
            email: errors.email,
            password: errors.password,
            confpassword: errors.confpassword,
          });
          setLoading(false);
        }
      })
      .catch(() => {
        setErrors({
          name: errors.name,
          username: 'Username already in use',
          email: errors.email,
          password: errors.password,
          confpassword: errors.confpassword,
        });
        setLoading(false);
      });
  };

  const checkValidation = async (userDt, key) => {
    let type;
    if (typeof key !== 'undefined') {
      type = key;
    } else {
      type = errorType;
    }
    switch (type) {
      case 'name':
        if (!userDt.name.length) {
          setLoading(false);
          setErrors({
            name: 'Display is required',
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else if (userDt.name.length < 5 && userDt.name.length) {
          setLoading(false);
          setErrors({
            name: 'Display min 5 character',
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else {
          setLoading(false);
          setErrors({
            name: '',
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        }
        break;

      case 'username':
        const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
        const emailRegexValidate = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
        const onlyUppercase = /^[a-z0-9]+$/;
        const isOnlyUppercase = onlyUppercase.test(userDt.username);
        const isContainSpecial = specialChar.test(userDt.username);
        const isEmail = emailRegexValidate.test(userDt.username);
        if (!userDt.username.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: 'Username is required',
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else if (userDt.username.length < 5 && userDt.username.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: 'Username min 5 character',
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else if (isContainSpecial && !isEmail) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: 'Username must not contain special character and space',
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else if (!isOnlyUppercase && userDt.username.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: 'Username must not contain uppercase',
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: '',
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        }
        break;

      case 'email':
        const emailRegex = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
        if (!userDt.email.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: 'Email is required',
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else if (!emailRegex.test(userDt.email) && userDt.email.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: 'Please check again your email',
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: '',
            country: errors.country,
            password: errors.password,
            confpassword: errors.confpassword,
          });
        }
        break;

      case 'country':
        const countries = getCountries();
        const find = countries.find((el) => el.name === userDt.country);
        if (typeof find === 'undefined') {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: 'Country not found',
            password: errors.password,
            confpassword: errors.confpassword,
          });
        } else {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: '',
            password: errors.password,
            confpassword: errors.confpassword,
          });
        }
        break;

      case 'password':
        if (!userDt.password.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: 'Password is required',
            confpassword: errors.confpassword,
          });
        } else if (userDt.password.length < 6 && userDt.password.length) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: 'Password min 6 character',
            confpassword: errors.confpassword,
          });
        } else {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: '',
            confpassword: errors.confpassword,
          });
        }
        break;

      case 'confpassword':
        if (userDt.password !== userDt.confpassword) {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: 'The password confirmation does not match',
          });
        } else {
          setLoading(false);
          setErrors({
            name: errors.name,
            username: errors.username,
            email: errors.email,
            country: errors.country,
            password: errors.password,
            confpassword: '',
          });
        }
        break;

      case 'final':
        let flag = false;
        for (const key in errors) {
          if (Object.hasOwnProperty.call(errors, key)) {
            const element = errors[key];
            if (element?.length) {
              flag = true;
              setLoading(false);
              return;
            }
          }
        }
        if (!flag) {
          if (newsletter) {
            sendNewsletter(userDt.email);
          }
          await userName(userDt);
          setTimeout(() => {
            setLoading(false);
          }, 1000);
        }
        break;

      default:
        break;
    }
  };

  const generateHash = (length) => {
    let result = ' ';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for (let i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }

    return result;
  };

  const getLoginSession = async (uid, type) => {
    const db = getDatabase();
    const dbRef = ref(db, '/');
    return await new Promise((resolve) => {
      get(child(dbRef, `/LoginSessions/${uid}/${type}`))
        .then((snap) => {
          if (snap.val()) {
            resolve(snap.val());
          } else {
            resolve('');
          }
        })
        .catch(() => {
          resolve([]);
        });
    });
  };

  const loginUser = async (email, password, type) => {
    const db = getDatabase();
    setLoading(true);
    const specialChar = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
    const emailRegex = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
    const isContainSpecial = specialChar.test(email);
    const isEmail = emailRegex.test(email);
    if (isContainSpecial && !isEmail) {
      setLoading(false);
      setErrorLogin({
        email: 'Username must not contain special character',
        password: '',
      });
      return;
    }

    if (!email && type === 'email/username') {
      setLoading(false);
      setErrorLogin({
        email: 'Email or Username is required',
        password: '',
      });
      return;
    }

    if (!password && type === 'email/username') {
      setLoading(false);
      setErrorLogin({
        email: '',
        password: 'Password is required',
      });
      return;
    }

    const userDetail = await loginHandler(email, password, type, () => setLoading(false));

    if ((type === 'facebook' || type === 'google' || type === 'apple') && navigator.userAgent.includes('Assemblr')) return;
    if (typeof userDetail === 'string') {
      switch (userDetail) {
        case 'Username not found':
          setErrorLogin({
            email: 'Username not found',
            password: '',
          });
          break;

        case 'User not found':
          setErrorLogin({
            email: 'User not found',
            password: '',
          });
          break;

        case 'Wrong password':
          setErrorLogin({
            email: '',
            password: 'Wrong password',
          });
          break;

        case 'error':
          setLoading(false);
          setLoginPopup('error');
          break;

        default:
          setLoading(false);
          break;
      }
      setLoading(false);
    } else {
      setUserDataDispatch({ ...userDetail });
      setLoading(false);
      setErrorLogin({
        email: '',
        password: '',
      });

      const hash = generateHash(12);
      localStorage.setItem('last_session_fetch', timestamp);
      if (navigator.userAgent.toLowerCase().includes('assemblr')) {
        const loginSessionMobile = await getLoginSession(userDetail.FirebaseID, 'Mobile');
        localStorage.setItem('login_session_mobile', hash);

        if (loginSessionMobile !== localStorage.getItem('login_session_mobile')) {
          set(ref(db, `/LoginSessions/${userDetail.FirebaseID}/Mobile`), hash);
        }
      } else {
        const loginSessionWeb = await getLoginSession(userDetail.FirebaseID, 'Web');
        localStorage.setItem('login_session_web', hash);

        if (loginSessionWeb !== localStorage.getItem('login_session_web')) {
          set(ref(db, `/LoginSessions/${userDetail.FirebaseID}/Web`), hash);
        }
      }

      if (!onSuccessLogin) {
        setLoginPopup('success');
      } else {
        handleEditorEmbedLogin();
      }

      if (navigator.userAgent.toLowerCase().includes('assemblr')) {
        window.location = `assemblr://login?userid=${userDetail.FirebaseID}`;
      }
      const auth = getAuth();
      const token = await getIdToken(auth.currentUser);
      const registerDate = new Date(auth.currentUser.metadata.creationTime).getTime();
      const currentDate = new Date('2023-01-15').getTime();
      if (currentDate < registerDate) {
        await registerReward(token, auth.currentUser.uid);
      }
      localStorage.setItem('show-reward-popup', false);
    }
  };

  useEffect(() => {
    const countries = getCountries();
    setCountryList(countries);
    localStorage.setItem('newsletter', 'false');
  }, []);

  useEffect(() => {
    const countries = getCountries();
    const countriesList = countries.filter((e) => {
      if (e.name.toLowerCase().includes(userData.country.toLowerCase())) {
        return e.name;
      }
    });
    setCountryList(countriesList);
  }, [userData.country]);

  useEffect(() => {
    if ((userData.country.length !== 0 && searchCountry)) {
      setSearchCountry(true);
    }
  }, [searchCountry, userData]);

  useEffect(() => {
    checkValidation(userData, errorType);
  }, [userData.name, userData.username]);

  useEffect(() => {
    if (errorType === 'final') {
      checkValidation(userData);
    }
    console.log('console.log();', errorType);
  }, [errorType]);

  useEffect(() => {
    const source = showLogin === 'login' ? 'login_main' : showLogin === 'register' ? 'login_register' : showLogin === 'forgot' ? 'login_forgotpass' : null;
    if (source) loginActivitiesAnalyticTracking(source);
  }, [showLogin]);

  const { listAppByKeyword, iframePopup: { url: iframePopupUrl } } = useSelector(appState);

  const backgroundImage = useMemo(() => listAppByKeyword?.login_banner_img?.attachment || listAppByKeyword?.login_banner_img?.value || '/assets/icons/login-screen.jpg', [listAppByKeyword]);

  const isEmbed = typeof onSuccessLogin === 'function';

  return (
    <div>
      <div className={classnames(styles.login_container, {
        '!bg-transparent': isEmbed,
      })}
      >
        <div className={`${styles.login_popup} ${styles.scrollbar}`}>
          <div
            onClick={() => {
              if (onClose) {
                onClose();
              } else {
                dispatch(setLogin(''));
                const mobileNav = document.getElementById('mobile_nav');
                sessionStorage.removeItem('triggerTutorial');
                if (iframePopupUrl) {
                  dispatch(setIframePopup({ open: false, url: '' }));
                }
                if (mobileNav) {
                  mobileNav.classList.add('fixed_mobile_nav');
                }
              }
            }}
            className={styles.close_button}
          >
            <span className="material-icons-round">
              close
            </span>
          </div>
          <div className={styles.login_header}>
            <img alt="" src={backgroundImage} />
          </div>
          {showLogin === 'login'
            && (
              <div className={styles.popup_content}>
                <p className={styles.text_header}>Log into Assemblr</p>
                <div className={styles.button_container}>
                  <div
                    onClick={() => {
                      loginActivitiesAnalyticTracking('login_main_apple');
                      loginUser(user.email.trim(), user.password, 'apple');
                    }}
                    className={`navbar-button ${styles.apple}`}
                  >
                    <FaApple className={`${styles.icon} ${styles.facebook}`} />
                    <p className={styles.text_login}>Sign in with Apple</p>
                  </div>
                  <div
                    onClick={() => {
                      loginActivitiesAnalyticTracking('login_main_google');
                      loginUser(user.email.trim(), user.password, 'google');
                    }}
                    className={`navbar-button ${styles.google} google`}
                  >
                    <FcGoogle className={styles.icon} />
                    <p className={`${styles.text_login} ${styles.google} google`}>Sign in with Google</p>
                  </div>
                  <div
                    onClick={() => {
                      loginActivitiesAnalyticTracking('login_main_fb');
                      loginUser(user.email.trim(), user.password, 'facebook');
                    }}
                    className={`navbar-button ${styles.facebook}`}
                  >
                    <FaFacebook className={`${styles.icon} ${styles.facebook}`} />
                    <p className={styles.text_login}>Sign in with Facebook</p>
                  </div>
                </div>
                <div className={styles.divider}>
                  <div />
                  <p>Or Sign In with</p>
                  <div />
                </div>
                <div className={styles.input_container}>
                  <input
                    value={user.email}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        loginUser(user.email.trim(), user.password, 'email/username');
                      }
                    }}
                    onChange={(e) => setUser({
                      email: e.target.value,
                      password: user.password,
                    })}
                    className={styles.input_container__input}
                    type="text"
                    id="username"
                    autoComplete="off"
                    placeholder="Hello there"
                  />
                  <label className={styles.input_container__label} htmlFor="username">Email or Username*</label>
                  {errorLogin.email.length
                    ? <p className={styles.error}>{errorLogin.email}</p>
                    : null}
                </div>
                <div className={styles.input_container}>
                  <input
                    value={user.password}
                    onKeyUp={(e) => {
                      if (e.key === 'Enter') {
                        loginUser(user.email.trim(), user.password, 'email/username');
                      }
                    }}
                    onChange={(e) => setUser({
                      email: user.email,
                      password: e.target.value,
                    })}
                    className={styles.input_container__input}
                    type="password"
                    id="password"
                    autoComplete="off"
                    placeholder="Hello there"
                  />
                  <label className={styles.input_container__label} htmlFor="password">Password*</label>
                  {errorLogin.password.length
                    ? <p className={styles.error}>{errorLogin.password}</p>
                    : null}
                </div>
                <div className={styles.button_container}>
                  <p onClick={() => dispatch(setLogin('forgot'))} className={styles.forgot_password}>Forgot Password?</p>
                  <div
                    onClick={() => loginUser(user.email.trim(), user.password, 'email/username')}
                    className={`navbar-button ${styles.sign_in}`}
                  >
                    <p className={styles.text_login}>Sign in</p>
                  </div>
                </div>
                <p className={styles.text_login}>
                  Don’t have an account?
                  {' '}
                  <span onClick={() => dispatch(setLogin('register'))} className={styles.link}>Sign Up Here</span>
                </p>
              </div>
            )}
          {showLogin === 'register'
            && (
              <div className={`${styles.popup_content} ${styles.scrollbar}`}>
                <p className={styles.text_header}>Create your Account</p>
                {showForm
                  ? (
                    <div>
                      <div className={styles.form_container}>
                        <div className={styles.input_container}>
                          <input
                            value={userData.name}
                            onClick={(e) => {
                              setErrorType('name');
                              setUserData({
                                name: e.target.value,
                                username: userData.username,
                                email: userData.email,
                                country: userData.country,
                                password: userData.password,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            onChange={(e) => {
                              setErrorType('name');
                              setUserData({
                                name: e.target.value,
                                username: userData.username,
                                email: userData.email,
                                country: userData.country,
                                password: userData.password,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            className={styles.input_container__input}
                            type="text"
                            id="name"
                            autoComplete="off"
                            placeholder="Hello there"
                          />
                          <label className={styles.input_container__label} htmlFor="name">Display Name</label>
                          {errors.name
                            ? <p className={styles.error}>{errors.name}</p>
                            : null}
                        </div>
                        <div className={styles.input_container}>
                          <input
                            value={userData.username}
                            onClick={(e) => {
                              setErrorType('username');
                              setUserData({
                                name: userData.name,
                                username: e.target.value,
                                email: userData.email,
                                country: userData.country,
                                password: userData.password,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            onChange={(e) => {
                              setErrorType('username');
                              setUserData({
                                name: userData.name,
                                username: e.target.value,
                                email: userData.email,
                                country: userData.country,
                                password: userData.password,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            className={styles.input_container__input}
                            type="text"
                            id="username"
                            autoComplete="off"
                            placeholder="Hello there"
                          />
                          <label className={styles.input_container__label} htmlFor="username">Username</label>
                          {errors.username
                            ? <p className={styles.error}>{errors.username}</p>
                            : null}
                        </div>
                        <div className={styles.input_container}>
                          <input
                            value={userData.email}
                            onClick={(e) => {
                              setErrorType('email');
                              setUserData({
                                name: userData.name,
                                username: userData.username,
                                email: e.target.value,
                                country: userData.country,
                                password: userData.password,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            onChange={(e) => {
                              setErrorType('email');
                              setUserData({
                                name: userData.name,
                                username: userData.username,
                                email: e.target.value,
                                country: userData.country,
                                password: userData.password,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            className={styles.input_container__input}
                            type="text"
                            id="email"
                            autoComplete="off"
                            placeholder="Hello there"
                          />
                          <label className={styles.input_container__label} htmlFor="email">Email</label>
                          {errors.email
                            ? <p className={styles.error}>{errors.email}</p>
                            : null}
                        </div>
                        <div className={styles.input_container}>
                          <input
                            value={userData.password}
                            onClick={(e) => {
                              setErrorType('password');
                              setUserData({
                                name: userData.name,
                                username: userData.username,
                                email: userData.email,
                                country: userData.country,
                                password: e.target.value,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            onChange={(e) => {
                              setErrorType('password');
                              setUserData({
                                name: userData.name,
                                username: userData.username,
                                email: userData.email,
                                country: userData.country,
                                password: e.target.value,
                                confpassword: userData.confpassword,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            className={styles.input_container__input}
                            type={`${showPassword ? 'text' : 'password'}`}
                            id="password"
                            autoComplete="off"
                            placeholder="Hello there"
                          />
                          <label className={styles.input_container__label} htmlFor={`${'password'}`}>Password</label>
                          {errors.password
                            ? <p className={styles.error}>{errors.password}</p>
                            : null}
                          <div onClick={() => setShowPassword(!showPassword)}>
                            {showPassword
                              ? <RiEyeLine className={`${styles.icon} ${styles.password}`} />
                              : <RiEyeCloseLine className={`${styles.icon} ${styles.password}`} />}
                          </div>
                        </div>
                        <div className={styles.input_container}>
                          <input
                            value={userData.confpassword}
                            onClick={(e) => {
                              setErrorType('confpassword');
                              setUserData({
                                name: userData.name,
                                username: userData.username,
                                email: userData.email,
                                country: userData.country,
                                password: userData.password,
                                confpassword: e.target.value,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            onChange={(e) => {
                              setErrorType('confpassword');
                              setUserData({
                                name: userData.name,
                                username: userData.username,
                                email: userData.email,
                                country: userData.country,
                                password: userData.password,
                                confpassword: e.target.value,
                                subcribe: userData.subcribe,
                                tnc: userData.tnc,
                              });
                            }}
                            className={styles.input_container__input}
                            type={`${showConfirmPassword ? 'text' : 'password'}`}
                            id="password_confirm"
                            autoComplete="off"
                            placeholder="Hello there"
                          />
                          <label className={styles.input_container__label} htmlFor="password_confirm">Confirm Password</label>
                          {errors.confpassword
                            ? <p className={styles.error}>{errors.confpassword}</p>
                            : null}
                          <div onClick={() => setShowConfirmPassword(!showConfirmPassword)}>
                            {showConfirmPassword
                              ? <RiEyeLine className={`${styles.icon} ${styles.password}`} />
                              : <RiEyeCloseLine className={`${styles.icon} ${styles.password}`} />}
                          </div>
                        </div>
                      </div>
                      <div className={styles.button_container}>
                        <div className={styles.checkbox}>
                          <input
                            className="!h-4 !w-4 min-w-4 max-w-4"
                            onClick={() => {
                              setNewsletter(!newsletter);
                              localStorage.setItem('newsletter', `${!newsletter}`);
                            }}
                            type="checkbox"
                          />
                          <span className="checkmark" />
                          <p>Subscribe to our newsletter & stay updated</p>
                        </div>
                        <div className={styles.checkbox}>
                          <input className="!h-4 !w-4 min-w-4 max-w-4" onClick={() => setTNC(!tnc)} type="checkbox" />
                          <span className="checkmark" />
                          <p>
                            I agree to Assemblr’s
                            {' '}
                            <a href={navigator.userAgent.toLowerCase().includes('assemblr') ? termLink.mobile : termLink.web} target={navigator.userAgent.toLowerCase().includes('assemblr') ? '' : '_blank'} rel="noreferrer">Terms & Condition</a>
                            {' '}
                            and
                            {' '}
                            <a href={navigator.userAgent.toLowerCase().includes('assemblr') ? policyLink.mobile : policyLink.web} target={navigator.userAgent.toLowerCase().includes('assemblr') ? '' : '_blank'} rel="noreferrer">Privacy Policy</a>
                          </p>
                        </div>
                        <div
                          onClick={() => {
                            if (!tnc) return;

                            let alertList = {};
                            const specialChar = /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/;
                            const emailRegexValidate = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
                            const emailRegex = /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
                            const onlyUppercase = /^[a-z0-9]+$/;
                            for (const key in userData) {
                              if (Object.hasOwnProperty.call(userData, key)) {
                                const element = userData[key];
                                if (!element) {
                                  const alert = key === 'name' ? 'Display is required' : key === 'username' ? 'Username is required' : key === 'email' ? 'Email is required' : key === 'password' ? 'Password is required' : key === 'confpassword' ? 'The password confirmation does not match' : null;
                                  if (alert) {
                                    alertList = {
                                      ...alertList,
                                      [key]: alert,
                                    };
                                  }
                                } else {
                                  if (key === 'username') {
                                    const isOnlyUppercase = onlyUppercase.test(element);
                                    const isContainSpecial = specialChar.test(element);
                                    const isEmail = emailRegexValidate.test(element);
                                    if (element.length < 5 && element.length) {
                                      setLoading(false);
                                      alertList = {
                                        ...alertList,
                                        [key]: 'Username min 5 character',
                                      };
                                    } else if (isContainSpecial && !isEmail) {
                                      setLoading(false);
                                      alertList = {
                                        ...alertList,
                                        [key]: 'Username must not contain special character and space',
                                      };
                                    } else if (!isOnlyUppercase && element.length) {
                                      setLoading(false);
                                      alertList = {
                                        ...alertList,
                                        [key]: 'Username must not contain uppercase',
                                      };
                                    }
                                  }
                                  if (key === 'email') {
                                    if (!emailRegex.test(element) && element.length) {
                                      setLoading(false);
                                      alertList = {
                                        ...alertList,
                                        [key]: 'Please check again your email',
                                      };
                                    }
                                  }
                                  if (key === 'password') {
                                    if (element.length < 6 && element.length) {
                                      setLoading(false);
                                      alertList = {
                                        ...alertList,
                                        [key]: 'Password min 6 character',
                                      };
                                    }
                                  }
                                  if (key === 'confpassword') {
                                    if (userData.password !== element) {
                                      setLoading(false);
                                      alertList = {
                                        ...alertList,
                                        [key]: 'The password confirmation does not match',
                                      };
                                    }
                                  }
                                }
                              }
                            }
                            if (Object.keys(alertList).length) {
                              setErrors(alertList);
                            } else {
                              setErrors({});
                              loginActivitiesAnalyticTracking('login_register_email_submitted');
                              setErrorType('final');
                              setLoading(true);
                            }
                          }}
                          className={` ${styles['navbar-button']} ${styles.sign_in} ${tnc ? '' : styles.hidden}`}
                        >
                          <p className={styles.text_login}>Sign Up</p>
                        </div>
                        <div className={styles.back_button}>
                          <p
                            onClick={() => {
                              loginActivitiesAnalyticTracking('login_register');
                              setShowForm(false);
                              setNewsletter(false);
                              localStorage.setItem('newsletter', 'false');
                            }}
                            className={styles.text_login}
                          >
                            Back

                          </p>
                        </div>
                      </div>
                    </div>
                  )
                  : (
                    <>
                      <p className={styles.text_desc}>
                        By creating an account, you agree to our
                        {' '}
                        <a href={navigator.userAgent.toLowerCase().includes('assemblr') ? termLink.mobile : termLink.web} target={navigator.userAgent.toLowerCase().includes('assemblr') ? '' : '_blank'} rel="noreferrer">Terms of Service</a>
                        {' '}
                        and have read and understood the
                        {' '}
                        <a href={navigator.userAgent.toLowerCase().includes('assemblr') ? policyLink.mobile : policyLink.web} target={navigator.userAgent.toLowerCase().includes('assemblr') ? '' : '_blank'} rel="noreferrer">Privacy Policy</a>
                      </p>
                      <div className={styles.button_container}>
                        <div
                          onClick={() => {
                            loginActivitiesAnalyticTracking('login_register_apple');
                            loginUser(user.email.trim(), user.password, 'apple', '');
                          }}
                          className={`navbar-button ${styles.apple}`}
                        >
                          <FaApple className={`${styles.icon} ${styles.facebook}`} />
                          <p className={styles.text_login}>Sign up with Apple</p>
                        </div>
                        <div
                          onClick={() => {
                            loginActivitiesAnalyticTracking('login_register_google');
                            loginUser(user.email.trim(), user.password, 'google', '');
                          }}
                          className={`navbar-button ${styles.google} google`}
                        >
                          <FcGoogle className={styles.icon} />
                          <p className={`${styles.text_login} ${styles.google} google`}>Sign up with Google</p>
                        </div>
                        <div
                          onClick={() => {
                            loginActivitiesAnalyticTracking('login_register_fb');
                            loginUser(user.email.trim(), user.password, 'facebook', '');
                          }}
                          className={`navbar-button ${styles.facebook}`}
                        >
                          <FaFacebook className={`${styles.icon} ${styles.facebook}`} />
                          <p className={styles.text_login}>Sign up with Facebook</p>
                        </div>
                        <div className={styles.checkbox}>
                          <input
                            className="!h-4 !w-4 min-w-4 max-w-4"
                            type="checkbox"
                            onClick={() => {
                              setNewsletter(!newsletter);
                              localStorage.setItem('newsletter', `${!newsletter}`);
                            }}
                          />
                          <span className="checkmark" />
                          <p>Subscribe to our newsletter & stay updated</p>
                        </div>
                      </div>
                      <div className={styles.divider}>
                        <div />
                        <p>Or Continue with</p>
                        <div />
                      </div>
                      <div className={styles.button_container}>
                        <div
                          onClick={() => {
                            loginActivitiesAnalyticTracking('login_register_email');
                            setShowForm(true);
                          }}
                          className={`navbar-button ${styles.with_email} with_email`}
                        >
                          <FiMail className={styles.icon} />
                          <p className={`${styles.text_login} ${styles.google} google`}>Continue with Email</p>
                        </div>
                      </div>
                      <p className={styles.text_login}>
                        Already have an account?
                        {' '}
                        <span onClick={() => dispatch(setLogin('login'))} className={styles.link}>Sign In</span>
                      </p>
                    </>
                  )}
              </div>
            )}
          {showLogin === 'forgot'
            && (
              <div className={styles.popup_content}>
                <p className={styles.text_header}>Forgot Password?</p>
                <p className={styles.text_desc}>
                  That’s ok... Just enter the email address you’ve used
                  to register with us and we’ll send you a reset link!
                </p>
                <div className={styles.form_container}>
                  <div className={styles.input_container}>
                    <input
                      value={user.email}
                      onChange={(e) => setUser({
                        email: e.target.value,
                        password: '',
                      })}
                      className={styles.input_container__input}
                      type="text"
                      id="email"
                      autoComplete="off"
                      placeholder="Hello there"
                    />
                    <label className={styles.input_container__label} htmlFor="email">Email</label>
                  </div>
                </div>
                <div className={styles.button_container}>
                  <div
                    onClick={() => {
                      loginActivitiesAnalyticTracking('login_forgotpass_submitted');
                      resetPassword(user.email.trim());
                    }}
                    className={`navbar-button ${styles.sign_in}`}
                  >
                    <p className={styles.text_login}>Submit</p>
                  </div>
                  <div className={styles.back_button}>
                    <p
                      onClick={() => {
                        setUser({
                          email: '',
                          password: '',
                        });
                        dispatch(setLogin('login'));
                      }}
                      className={styles.text_login}
                    >
                      Back to log in

                    </p>
                  </div>
                </div>
              </div>
            )}

          {
              showLogin === '' && isEmbed && (
                <div className={classnames(styles.container_popup, 'success_login_embed_container !w-full')}>
                  <img src="/assets/icons/success.svg" alt="" className="h-24 mt-5" />
                  <p className={styles.header_success}>Sign in Success</p>
                  <p className={styles.content_success}>
                    You are successfully logged in to your account
                  </p>
                  <div
                    onClick={() => {
                      if (onSuccessLogin) {
                        handleEditorEmbedLogin();
                      } else {
                        setLoginPopup('');
                      }
                    }}
                    className={classnames(styles.button_success, 'mb-5')}
                  >
                    <p>Okay</p>
                  </div>
                </div>
              )
            }
        </div>
        {loading
          ? <Loader /> : null}
        {loginPopup === 'success'
          ? (
            <div className="scrim-loading">
              <div className={styles.container_popup}>
                {!onSuccessLogin && <GrClose onClick={() => setLoginPopup('')} className={styles.close_success} />}
                <img src="/assets/icons/success.svg" alt="" className="h-24 mt-5" />
                <p className={styles.header_success}>Sign in Success</p>
                <p className={styles.content_success}>
                  You are successfully logged in to your account
                </p>
                <div
                  onClick={() => {
                    if (onSuccessLogin) {
                      handleEditorEmbedLogin();
                    } else {
                      setLoginPopup('');
                    }
                  }}
                  className={classnames(styles.button_success, 'mb-5')}
                >
                  <p>Okay</p>
                </div>
              </div>
            </div>
          ) : null}
        {loginPopup === 'error'
          ? (
            <div className="scrim-loading">
              <div className={styles.container_popup}>
                <GrClose onClick={() => setLoginPopup('')} className={styles.close_success} />
                <img src="/assets/icons/login_error.svg" alt="" />
                <p className={styles.header_success}>Sign in Failed</p>
                <p className={styles.content_success}>
                  There something error. Enter a different account or
                  {' '}
                  <span onClick={() => {
                    setLoginPopup('');
                    dispatch(setLogin('login'));
                  }}
                  >
                    register a new one
                  </span>

                </p>
                <div
                  onClick={() => {
                    setLoginPopup('');
                    dispatch(setLogin('login'));
                  }}
                  className={`${styles.button_success} ${styles.error_success}`}
                >
                  <p>Try Again</p>
                </div>
              </div>
            </div>
          ) : null}
      </div>
    </div>
  );
}
